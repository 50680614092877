import { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EditIcon from '@mui/icons-material/Edit';

import { ToastContainer, toast } from 'react-toastify';
import clienteAxios from '../config/clienteAxios';

import { context } from "../context/context";

const ModalEditOrder = ({orderData}) => {

    const { get_data_client, get_orders_client } = useContext(context);

    const [show, setShow] = useState(false);
    const [newOrder, setNewOrder] = useState({
        product: orderData.product,
        kilograms: orderData.kilograms,
        price: orderData.price,
        total: orderData.total,
        notes: orderData.notes
    });
    let todayDate = new Date(orderData.date_register)
    const [selectedDate, setSelectedDate] = useState(todayDate);

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };


    useEffect(() => {
      const calculateTotal = () => {
        const newTotal = (newOrder.kilograms * newOrder.price).toFixed(2);
        setNewOrder(prevOrder => ({ ...prevOrder, total: Number(newTotal) }));
      };
  
      calculateTotal();
    }, [newOrder.kilograms, newOrder.price])
    

    const handleSuccess = async() => {

        if(!newOrder.kilograms || !newOrder.price || !newOrder.product){
            toast.error("Se requiere el tipo de producto, kilogramos y precio", {
                position: "top-center",
                theme: "dark",
            });
            return null
        }
        newOrder.date_register = selectedDate
        try {
             await clienteAxios.put(
                 `/api/order/edit_order/${orderData._id}`,
                 newOrder
             );
            await get_data_client(orderData.client_id._id)
            await get_orders_client(orderData.client_id._id)
            setShow(false)
        } catch (error) {
            console.log(error.name, error.message);
            toast.error("Error en el servidor", {
                position: "top-center",
                theme: "dark",
            });
        }

        
    };
    const handleClose = () => {

      setNewOrder({
            ...newOrder,
            product: orderData.product,
            kilograms: orderData.kilograms,
            price: orderData.price,
            total: orderData.total,
            notes: orderData.notes
        })

        let todayDate = new Date(orderData.date_register)
        setSelectedDate(todayDate)

        setShow(false)
    };
    const handleShow = () => setShow(true);


    const handleChange = (e) => {
      if(e.target.name ==="kilograms" || e.target.name ==="price"){
        setNewOrder({ ...newOrder, [e.target.name]: Number(e.target.value) });
      }else{
        setNewOrder({ ...newOrder, [e.target.name]: e.target.value });
      }

    };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <EditIcon/>
      </Button>

      <Modal show={show} onHide={handleClose} style={{width: "100vw"}}>
        <Modal.Header closeButton>
          <Modal.Title>Editar orden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Tipo de producto</Form.Label>
              <Form.Control
                type="text"
                name="product"
                placeholder="Sandía amarilla"
                onChange={handleChange}
                value={newOrder.product}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Kilogramos</Form.Label>
              <Form.Control
                type="number"
                name="kilograms"
                placeholder="10000"
                onChange={handleChange}
                value={newOrder.kilograms}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Precio</Form.Label>
              <Form.Control
                type="number"
                name="price"
                placeholder="50.0"
                onChange={handleChange}
                value={newOrder.price}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Total</Form.Label>
              <Form.Control
                type="number"
                name="total"
                placeholder="10000"
                value={newOrder.total}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Fecha registro</Form.Label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Notas</Form.Label>
              <Form.Control
                type="text"
                name="notes"
                placeholder="Notas"
                onChange={handleChange}
                value={newOrder.notes}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" onClick={handleSuccess}>
            Editar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer/>
    </>
  )
}

export default ModalEditOrder
