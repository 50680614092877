import RootRoutes from "./routers/RootRoutes";
// import LeftMenu from "./components/LeftMenu";

import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { context } from "./context/context";
import InputText from "./components/InputText";
import UpMenu from "./components/UpMenu";

function App() {

  const { login, login_success, logout} = useContext(context)
  
  const navigate = useNavigate();

  useEffect(() => {
    const validateLogin = async() => {
      const localToken = await localStorage.getItem("user")
      if(localToken != null){
        login_success()
        navigate("/home");
      }else {
        logout()
      }
    }

   validateLogin()
  
  }, [])
  


  return (
    <div className="app">
      {login && <UpMenu />}
      {/* {login && <LeftMenu />} */}
      <div className="search_roots">
        {login && 
          <div className="search-container">
            <label className="label-input" htmlFor="cliente">Búsqueda por cliente</label>
            <InputText id="cliente" placeholder="nombre cliente" />
          </div>
        }
        <RootRoutes className="rootContainer" />
      </div>
    </div>
  );
}

export default App;
