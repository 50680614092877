import React from 'react'
import NewClient from '../components/NewClient'

const CreateClient = () => {
  return (
    <div className='createClient'>
      <NewClient/>
    </div>
  )
}

export default CreateClient