import { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { context } from "../context/context";

import DeleteIcon from '@mui/icons-material/Delete';

import clienteAxios from '../config/clienteAxios';

const ModalDeleteDeposit = ({dataDeposit}) => {

    const navigate = useNavigate();
    const { get_data_client, get_deposits_client } = useContext(context);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleSuccess = async() => {
        const response = await clienteAxios.delete(`/api/deposit/delete_deposit/${dataDeposit._id}`);
        await get_data_client(dataDeposit.client_id._id)
        await get_deposits_client(dataDeposit.client_id._id)
        // if(text === "cliente"){
        //     navigate(`/home`);
        // }

        // if(text === "proveedor"){
        //     navigate(`/suppliers`);
        // }
        setShow(false)
    }
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="danger" onClick={handleShow}>
          <DeleteIcon />
        </Button>
  
        <Modal show={show} onHide={handleClose} style={{width: "100vw"}}>
          <Modal.Header closeButton>
            <Modal.Title>¿Eliminar este depósito?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Si aceptas, se eliminará por completo este depósito</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleSuccess}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default ModalDeleteDeposit