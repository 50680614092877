import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import clienteAxios from "../config/clienteAxios";
import Button from "../components/Button";
import { useState } from "react";
import authToken from "../helpers/authToken";

const NewAdmin = () => {
  const [admin, setAdmin] = useState({
    nombre: "",
    correo: "",
    contrasena: "",
  });

  const handleChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    authToken();

    try {
      const response = await clienteAxios.post(
        "/api/admin/register_admin",
        admin
      );
      localStorage.setItem("user", response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="newadmin">
      <label htmlFor="user">
        <AssignmentIndIcon />
      </label>
      <label htmlFor="name">Nombre Admin</label>
      <div className="divInputForm">
        <input
          type="text"
          id="nombre"
          name="nombre"
          placeholder="Nombre administrador"
          required
          onChange={handleChange}
        />
      </div>
      <label htmlFor="correo">Correo</label>
      <div className="divInputForm">
        <input
          type="email"
          id="correo"
          name="correo"
          placeholder="ejemplo@mail.com"
          required
          onChange={handleChange}
        />
      </div>
      <label htmlFor="contrasena">Contraseña</label>
      <div className="divInputForm">
        <input
          type="password"
          id="contrasena"
          name="contrasena"
          placeholder="*******"
          required
          onChange={handleChange}
        />
      </div>
      <Button
        className="create"
        label="Crear administrador 🍉"
        onClick={handleSubmit}
      />
      <a className="cancel" href="cancel">
        Cancelar
      </a>
    </form>
  );
};

export default NewAdmin;
