import { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ToastContainer, toast } from 'react-toastify';
import clienteAxios from '../config/clienteAxios';

import { context } from "../context/context";

const ModalNewDeposit = ({id_client}) => {

    const { get_data_client } = useContext(context);
    let todayDate = new Date()
    const [selectedDate, setSelectedDate] = useState(todayDate);

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };

    const [show, setShow] = useState(false);
    const [newDeposit, setNewDeposit] = useState({
        client_id: id_client,
        deposit_type: "",
        total_deposit: 0,
        notes: ""
    });

    const handleSuccess = async() => {
        if(!newDeposit.deposit_type || !newDeposit.total_deposit){
            toast.error("Se requiere el tipo de depósito y el total", {
                position: "top-center",
                theme: "dark",
            });
            return null
        }
        newDeposit.date_register = selectedDate
        
        try {
            await clienteAxios.post(
                "/api/deposit/create_deposit",
                newDeposit
                );
            get_data_client(id_client)
            setShow(false)
        } catch (error) {
            toast.error("Error en el servidor", {
                position: "top-center",
                theme: "dark",
            });
        }

        
    };
    const handleClose = () => {

        setNewDeposit({
            ...newDeposit,
            deposit_type: "",
            total_deposit: 0
        })

        setShow(false)
    };
    const handleShow = () => setShow(true);


    const handleChange = (e) => {

      if(e.target.name ==="total_deposit"){
        setNewDeposit({ ...newDeposit, [e.target.name]: Number(e.target.value) });
      }else{
        setNewDeposit({ ...newDeposit, [e.target.name]: e.target.value });
      }

    };

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Crear Depósito
      </Button>

      <Modal show={show} onHide={handleClose} style={{width: "100vw"}}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Depósito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Tipo de depósito</Form.Label>
              <Form.Select aria-label="Default select example" name='deposit_type' onChange={handleChange}>
                <option>Tipo de pago</option>
                <option value="transferencia">Transferencia</option>
                <option value="deposito">Depósito</option>
                <option value="efectivo">Efectivo</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Fecha registro</Form.Label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Total depósito</Form.Label>
              <Form.Control
                type="number"
                name="total_deposit"
                placeholder="565.5"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Notas</Form.Label>
              <Form.Control
                type="text"
                name="notes"
                placeholder="Notas"
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" onClick={handleSuccess}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer/>
    </>
  )
}

export default ModalNewDeposit
