import React from 'react'
import NewOrder from '../components/NewOrder'

const CreateOrder = () => {
  return (
    <div className='createOrder'>
      <NewOrder/>
    </div>
  )
}

export default CreateOrder