import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from './Button';

const NewClient = () => {
  return (
    <form className='newclient'>
    <label htmlFor="user"><AccountCircleIcon/></label>
    <label htmlFor="name">Nombre</label>
    <div className='divInputForm'><input type="text" id="nombre" placeholder='Nombre completo'/></div>
    <label htmlFor="phone">Celular</label>
    <div className="divInputForm"><input type="text" id="numero" placeholder="Número celular" /></div>
    <label htmlFor="correo">Correo</label>
    <div className="divInputForm"><input type="email" id="correo" placeholder='ejemplo@mail.com' /></div>
    <label htmlFor="adress">Dirección</label>
    <div className="divInputForm"><input type="text" id="direccion" placeholder='Calle Bugambilia 123 ...' /></div>
    <label htmlFor="business">Nombre del negocio</label>
    <div className="divInputForm"><input type="text" id='negocio' placeholder='"Las Frutillas"' /></div>
    <Button label="Crear cliente 🍉"/>
    <a className='cancel' href="cancel">Cancelar</a>
    </form>
  )
}

export default NewClient