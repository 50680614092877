import { useContext, useEffect } from 'react'
import { context } from "../context/context";
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';


function ToPaySuppliers() {
    const { get_to_pay_suppliers, suppliers_to_pay } = useContext(context);

    useEffect(() => {
        get_to_pay_suppliers();
    }, [])
  
    return (
      <div className='allClients'>
        <div className='containerTable'>
        { suppliers_to_pay.length > 0 && (
          <Table striped="columns" bordered hover variant="primary">
            <thead>
              <tr>
                <th><h4>Nombre</h4></th>
                <th><h4>Teléfono</h4></th>
                <th><h4>Saldo total</h4></th>
              </tr>
            </thead>
            <tbody>
  
          {suppliers_to_pay.map(client=>(
            <tr key={client._id}>
                <td><Link to={`/detail_supplier/${client._id}`}>{client.name}</Link></td>
                <td>{client.cell_phone}</td>
                <td>-${client.balance}</td>
            </tr>
          ))}
            
          </tbody>
        </Table>
        )}
        </div>
      </div>
  
  
    )
}

export default ToPaySuppliers