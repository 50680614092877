import React from 'react'
import NewAdmin from '../components/NewAdmin'

const CreateAdmin = () => {
  return (
    <div className='createAdmin'>
      <NewAdmin/>
    </div>
  )
}

export default CreateAdmin