import { useContext } from 'react'
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { context } from "../context/context";

const SearchClient = () => {


    const { resultsSearchClients } = useContext(context);



  return (
    <div className='allClients'>
        <div>Resultados: {resultsSearchClients.length}</div>
        <div className='containerTable'>
        { resultsSearchClients.length > 0 && (
        <Table striped bordered hover variant="primary">
          <thead>
            <tr>
              <th><h4>Nombre</h4></th>
              <th><h4>Teléfono</h4></th>
              <th><h4>Teléfono opcional</h4></th>
            </tr>
          </thead>
          <tbody>

        {resultsSearchClients.map(client=>(
          <tr>
              <td><Link to={`/detail_client/${client._id}`}>{client.name}</Link></td>
              <td>{client.cell_phone}</td>
              <td>{client.extra_phone}</td>
          </tr>
        ))}
          
        </tbody>
      </Table>
      )}
        </div>
    </div>
  )
}

export default SearchClient