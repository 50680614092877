import axios from "axios";

const clienteAxios = axios.create({
  baseURL: "https://back-chavalin.vercel.app/",
  // baseURL: "http://3.17.179.71:3000/",
  headers: {
    'Content-Type': 'application/json',  
  },
});

export default clienteAxios;
