import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useEffect } from 'react';
import clienteAxios from '../config/clienteAxios';

import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';

const OnlyClients = () => {

  let { id_client } = useParams();

  const [key, setKey] = useState('ordenes');
  const [dataClient, setDataClient] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    const getDataClient = async () => {
      try {
        const response = await clienteAxios.get(`api/client/only_clients/${id_client}`);
        setDataClient(response.data.data)
      } catch (error) {
        setError(true)
      }
    }

    getDataClient()

    return () => {
      setDataClient(null)
    }
  }, [])

  // <h3>Órdenes de: {orders_client[0].client_id.name}</h3>
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >

      <Tab eventKey="ordenes" title="Órdenes">
        {!dataClient && error ? <div>Hubo un error</div> : !dataClient ? <div>Cargando...</div> : (

          <div style={{margin: "30px"}}>
            <h3>Órdenes de: {dataClient.client.name}</h3>
            <Table striped="columns" bordered hover variant="primary">
              <thead>
                <tr>
                  <th><h6>Fecha</h6></th>
                  <th><h6>Kilogramos</h6></th>
                  <th><h6>Precio</h6></th>
                  <th><h6>Total</h6></th>
                  <th><h6>Notas</h6></th>
                  <th><h6>Producto</h6></th>
                </tr>
              </thead>
              <tbody>
                {dataClient.orders.map(order => (
                  <tr key={order._id}>
                    <td>{moment.tz(order.date_register, 'America/Mexico_City').format("DD-MM-YYYY")}</td>
                    <td>{order.kilograms} kg</td>
                    <td>${order.price}</td>
                    <td>${order.total}</td>
                    <td>{order.notes}</td>
                    <td>{order.product}</td>
                  </tr>
                ))}

              </tbody>
            </Table>

          </div>
        )}
      </Tab>
      <Tab eventKey="pagos" title="Pagos">
      {!dataClient && error ? <div>Hubo un error</div> : !dataClient ? <div>Cargando...</div> : (
        <div style={{margin: "30px"}}>
          <h3>Depósitos de: {dataClient.client.name}</h3>
          <Table striped="columns" bordered hover variant="primary">
            <thead>
              <tr>
                <th><h6>Fecha</h6></th>
                <th><h6>Tipo</h6></th>
                <th><h6>Total</h6></th>
                <th><h6>Notas</h6></th>
              </tr>
            </thead>
            <tbody>
          {dataClient.deposits.map(deposit=>(
            <tr key={deposit._id}>
                <td>{moment.tz(deposit.date_register, 'America/Mexico_City').format("DD-MM-YYYY")}</td>
                <td>{deposit.deposit_type}</td>
                <td>${deposit.total_deposit}</td>
                <td>{deposit.notes}</td>
            </tr>
          ))}
          
          </tbody>
                </Table>
        </div>
      )}
      </Tab>
      <Tab eventKey="balance" title="Saldo Total">
      {!dataClient && error ? <div>Hubo un error</div> : !dataClient ? <div>Cargando...</div> : (
        <div style={{margin: "30px"}}>
          <h3>Saldo total: <b style={{color: dataClient.total >= 0 ? "green" : "red"}}>${dataClient.total}</b></h3>
        </div>
      )}
      </Tab>

    </Tabs>
  );
}

export default OnlyClients