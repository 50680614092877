import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Button from './Button';
import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import clienteAxios from '../config/clienteAxios';

import { ToastContainer, toast } from 'react-toastify';


import { context } from "../context/context";


const LoginForm = () => {

  const [login, setLogin] = useState({
    name: "",
    password: ""
  });

  const { login_success } = useContext(context)

  const navigate = useNavigate();

  const handleChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    try {
      const response = await clienteAxios.post(
        "api/admin/login",
        login
      );
      await localStorage.setItem("user", response.data.data);
      await login_success()
      navigate("/home");
    } catch (error) {
      toast.error("Usuario o contraseña inválidos", {
        position: "top-center",
        theme: "dark",
      });
      console.log(`${error.name} - ${error.message}`);
    }
  };


  return (
    <form className="form">
      <label htmlFor="name"><PersonIcon/>Nombre</label>
      <div className="divInputForm">
        <input 
          type="text" 
          id="name"
          name="name" 
          onChange={handleChange}
        />
      </div>
      <label htmlFor="contrasena"><LockIcon/>Contraseña</label>
      <div className="divInputForm">
        <input 
          type="password" 
          id="contraseña"
          name="password" 
          placeholder="*******" 
          onChange={handleChange}
        />
      </div>
      <Button label="entrar" onClick={handleSubmit}/>
      <a href="recovery">Recuperar contraseña 🍉</a>
      <ToastContainer />
    </form>
  );
};

export default LoginForm;
