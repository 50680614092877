import LoginForm from "../components/LoginForm"

const Login = () => {
  return (
    <div className="login_form">
        <LoginForm/>
    </div>
  )
}

export default Login