import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { ToastContainer, toast } from 'react-toastify';

import { context } from "../context/context";


const InputText = ({ placeholder, id }) => {
  const [searchItem, setSearchItem] = useState("");
  const navigate = useNavigate();

  const { search_clients } = useContext(context);


  const searchClient = (e) => {
    e.preventDefault()

    if(!searchItem){
      toast.error("Ingresa el nombre de un usuario", {
        position: "top-center",
        theme: "dark",
      });
      return null
    }
    search_clients(searchItem)
    navigate(`/search_client/${searchItem}`);
  }

  const handleChange = (e) => {
    setSearchItem(e.target.value);
  };


  return (
    <>
      <form onSubmit={e=>searchClient(e)} className="formSearch">
        <input
          className="input-text"
          type="text"
          id={id}
          placeholder={placeholder}
          onChange={handleChange}
        />
        <SearchIcon className="buttonSearch" onClick={searchClient}/>
        <ToastContainer />
      </form>
    </>
  );
};

export default InputText;
