import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

import clienteAxios from '../config/clienteAxios';
import { Link } from 'react-router-dom';

const AllSuppliers = () => {

  const [clients, setClients] = useState([])

  useEffect(() => {
    const getClients = async() => {
      const response = await clienteAxios.get("/api/client/all_suppliers");
      setClients(response.data.data)
    }

    getClients()
  
  }, [])
  


  return (
    <div className='allClients'>
      <div className='containerTable'>
      { clients.length > 0 && (
        <Table striped="columns" bordered hover variant="primary">
          <thead>
            <tr>
              <th><h4>Nombre</h4></th>
              <th><h4>Teléfono</h4></th>
              <th><h4>Teléfono opcional</h4></th>
            </tr>
          </thead>
          <tbody>

        {clients.map(client=>(
          <tr key={client._id}>
              <td><Link to={`/detail_supplier/${client._id}`}>{client.name}</Link></td>
              <td>{client.cell_phone}</td>
              <td>{client.extra_phone}</td>
          </tr>
        ))}
          
        </tbody>
      </Table>
      )}
      </div>
    </div>
  )
}

export default AllSuppliers