import { createContext, useReducer } from "react";
import reducer from "./reducer";
import clienteAxios from "../config/clienteAxios";
import authToken from "../helpers/authToken";

export const context = createContext();

const Provider = ({ children }) => {
  const initialState = {
    admin: null,
    clients: [],
    orders: [],
    loading: false,
    error: false,
    login: false,
    dataHome: [],
    dataClient: null,
    boxDataUser: [],
    resultsSearchClients: [],
    clients_to_pay: [],
    orders_client: [],
    deposits_client: [],
    dataHomeSupplier: [],
    suppliers_to_pay: []

  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const addAdmin = (admin) => {
    dispatch({
      type: "ADD_ADMIN",
      payload: admin,
    });
  };

  const deleteAdmin = () => {
    dispatch({
      type: "DELETE_ADMIN",
    });
  };

  const chargeClients = async () => {
    try {
      dispatch({
        type: "CHARGING_DATA",
        payload: true
      })

      const response = await clienteAxios.get("/api/client/all_clients");
      dispatch({
        type: "ALL_CLIENTS",
        payload: response.data.data,
      })
      dispatch({ type: 'CHARGING_DATA', payload: false });

    } catch (error) {
      console.error('Error:', error);
    }
    

  };

  const logout = () => {
    localStorage.removeItem('user');
    dispatch({
      type: "LOGOUT",
    });
  };

  const login_success = () => {
    dispatch({
      type: "LOGIN",
      payload: true
    });
  };

  const get_data_home = async() => {
    await authToken()
    dispatch({
      type: "CHARGING_DATA",
      payload: true
    })
    let dataResp = await clienteAxios.get("api/home/home")
    dispatch({
      type: "GET_DATA_HOME",
      payload: dataResp.data.data
    });
    dispatch({
      type: "CHARGING_DATA",
      payload: false
    })
  };

  const get_data_home_supplier = async() => {
    await authToken()
    let dataResp = await clienteAxios.get("api/home/home_supplier")
    dispatch({
      type: "GET_DATA_HOME_SUPPLIER",
      payload: dataResp.data.data
    });
  };

  const get_data_client = async(id_client) => {
    await authToken()
    let dataResp = await clienteAxios.get(`/api/client/client_by_id/${id_client}`)
    dispatch({
      type: "GET_DATA_CLIENT",
      payload: dataResp.data.data
    });
  };

  const close_client = () => {
    dispatch({
      type: "CLOSE_CLIENT"
    });
  };

  const close_home = () => {
    dispatch({
      type: "CLOSE_HOME"
    });
  };

  const get_to_pay = async () => {
    await authToken()
    dispatch({
      type: "CHARGING_DATA",
      payload: true
    })
    let dataResp = await clienteAxios.get(`/api/client/to_pay`)

    dispatch({
      type: "GET_DATA_TO_PAY",
      payload: dataResp.data.data
    });

    dispatch({
      type: "CHARGING_DATA",
      payload: false
    })
  }

  const get_to_pay_suppliers = async () => {
    await authToken()

    let dataResp = await clienteAxios.get(`/api/client/to_pay_suppliers`)

    dispatch({
      type: "GET_DATA_TO_PAY_SUPPLIERS",
      payload: dataResp.data.data
    });
  }

  const search_clients = async(search_item)=> {
    await authToken()

    const dataResp = await clienteAxios.get(`/api/client/search_client?item_search=${search_item}`);

    dispatch({
      type: "SEARCH_CLIENTS",
      payload: dataResp.data.data
    });
  }

  const get_orders_client = async(id_user)=> {
    await authToken()

    const dataResp = await clienteAxios.get(`/api/order/orders_by_client/${id_user}`);

    dispatch({
      type: "GET_ORDERS_CLIENT",
      payload: dataResp.data.data
    });
  }

  const close_client_orders = () => {
    dispatch({
      type: "CLOSE_CLIENT_ORDERS"
    });
  };

  const get_deposits_client = async(id_user)=> {
    await authToken()

    const dataResp = await clienteAxios.get(`/api/deposit/deposits_by_client/${id_user}`);

    dispatch({
      type: "GET_DEPOSITS_CLIENT",
      payload: dataResp.data.data
    });
  }

  const close_client_deposits = () => {
    dispatch({
      type: "CLOSE_CLIENT_DEPOSITS"
    });
  };

  const delete_client = async(id_user) => {
    await authToken()

    await clienteAxios.delete(`/api/client/delete_client/${id_user}`);

    dispatch({
      type: "DELETE_CLIENT"
    });

  }

  const charge_data = (actionValue) => {
    dispatch({
      type: "CHARGING_DATA",
      payload: actionValue
    })
  }

  return (
    <context.Provider
      value={{
        admin: state.admin,
        clients: state.clients,
        orders: state.orders,
        totalOrdenes: state.totalOrdenes,
        totalAdeudo: state.totalAdeudo,
        totalPagado: state.totalPagado,
        login: state.login,
        dataHome: state.dataHome,
        dataClient: state.dataClient,
        boxDataUser: state.boxDataUser,
        resultsSearchClients: state.resultsSearchClients,
        clients_to_pay: state.clients_to_pay,
        orders_client: state.orders_client,
        deposits_client: state.deposits_client,
        dataHomeSupplier: state.dataHomeSupplier,
        suppliers_to_pay: state.suppliers_to_pay,
        loading: state.loading,
        addAdmin,
        deleteAdmin,
        logout,
        chargeClients,
        login_success,
        get_data_home,
        get_data_client,
        close_client,
        search_clients,
        get_to_pay,
        get_orders_client,
        close_client_orders,
        get_deposits_client,
        close_client_deposits,
        get_data_home_supplier,
        get_to_pay_suppliers,
        delete_client,
        charge_data,
        close_home
      }}
    >
      {children}
    </context.Provider>
  );
};

export default Provider;
