import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState, useContext } from 'react';
import { context } from "../context/context";
import { Link } from 'react-router-dom';
import logo from '../images/logo.PNG'

import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LogoutIcon from '@mui/icons-material/Logout';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';


export default function UpMenu() {

    const { logout } = useContext(context)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCloseLogout = () => {
        setAnchorEl(null);
        logout()
    };
  
    return (
      <div className='upMenu'>
        <div>
          <img src={logo} alt="logo" className='logoChavalin'/>
        </div>
        <div>
            <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="contained"
            color="success"
            >
            Menú
            </Button>
            <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            >
            <Link to="/home" className='homeLi'><MenuItem onClick={handleClose}><HomeIcon />Inicio</MenuItem></Link>
            <Link to="/all_clients" className='homeLi'><MenuItem onClick={handleClose}><ContactPhoneIcon/> Clientes</MenuItem></Link>
            <Link to="/suppliers" className='homeLi'><MenuItem onClick={handleClose}><LocalShippingIcon/>Proveedores</MenuItem></Link>
            <Link to=""><MenuItem onClick={handleCloseLogout}><LogoutIcon/>Salir</MenuItem></Link>
            </Menu>
        </div>
      </div>
    );
  }