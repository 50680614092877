import { useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';

import { Link } from 'react-router-dom';


import { context } from "../context/context";
import LoadingComponent from '../components/LoadingComponent';

const AllClients = () => {

  const { clients, chargeClients, loading} = useContext(context);

  useEffect(() => {
    chargeClients()
  
  }, [])
  


  return (
    <div className='allClients'>
      <div className='containerTable'>
      {loading ? <LoadingComponent /> : (
        <>
          { clients.length > 0 && (
            <Table striped="columns" bordered hover variant="primary">
              <thead>
                <tr>
                  <th><h4>Nombre</h4></th>
                  <th><h4>Teléfono</h4></th>
                  <th><h4>Teléfono opcional</h4></th>
                </tr>
              </thead>
              <tbody>

            {clients.map(client=>(
              <tr key={client._id}>
                  <td><Link to={`/detail_client/${client._id}`}>{client.name}</Link></td>
                  <td>{client.cell_phone}</td>
                  <td>{client.extra_phone}</td>
              </tr>
            ))}
              
            </tbody>
          </Table>
          )}
        </>
      )}
      
      </div>
    </div>
  )
}

export default AllClients