import React from 'react'
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { context } from "../context/context";
import moment from 'moment-timezone';
import ModalDeleteDeposit from '../components/ModalDeleteDeposit';
import ModalEditDeposit from '../components/ModalEditDeposit';


const AllDeposits = () => {

  let { id_client } = useParams();
  const { get_deposits_client, deposits_client, close_client_deposits } = useContext(context);

  useEffect(() => {
      
    get_deposits_client(id_client)
  
    return () => {
      close_client_deposits();
    };

}, [])

  return (
    <div className='allClients'>
        <div className='containerTable'>
        { deposits_client.length > 0 && (
        <>
        <h3>Depósitos de: {deposits_client[0].client_id.name}</h3>
          <Table striped="columns" bordered hover variant="primary">
            <thead>
              <tr>
                <th><h6>Fecha</h6></th>
                <th><h6>Tipo</h6></th>
                <th><h6>Total</h6></th>
                <th><h6>Notas</h6></th>
                <th><h6>Editar</h6></th>
                <th><h6>Eliminar</h6></th>
                <th><h6>Registrado por</h6></th>
              </tr>
            </thead>
            <tbody>
          {deposits_client.map(deposit=>(
            <tr key={deposit._id}>
                <td>{moment.tz(deposit.date_register, 'America/Mexico_City').format("DD-MM-YYYY")}</td>
                <td>{deposit.deposit_type}</td>
                <td>${deposit.total_deposit}</td>
                <td>{deposit.notes}</td>
                <td><ModalEditDeposit dataDeposit={deposit}/> </td>
                <td><ModalDeleteDeposit dataDeposit={deposit}/> </td>
                <td>{deposit.created_by.name}</td>
            </tr>
          ))}
          
          </tbody>
                </Table>
        </>
      )}
      {deposits_client.length === 0 && (
            <h2>No hay depósitos realizados</h2>
          )}
        </div>
    </div>
  )
}

export default AllDeposits