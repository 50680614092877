import { useContext, useEffect } from "react";
import { context } from "../context/context";

import ModalNewClient from "../components/ModalNewClient"
import BoxHome from "../components/BoxHome";

export const Suppliers = () => {

  const { get_data_home_supplier, dataHomeSupplier } = useContext(context);

  useEffect(() => {
    get_data_home_supplier()
  }, [])


  return (
    <div className="home">
      <div className="modalButtonClient">
        <ModalNewClient supplier={true} />
      </div>
      <div className="containerBox">

        {dataHomeSupplier.map(boxData =>(
          <BoxHome key={boxData.text} text={boxData.text} total={boxData.data} />
        ))}

      </div>
    
    </div>
  )
}
