import { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { context } from "../context/context";

const ModalDeleteClient = ({id_client, text}) => {

    const navigate = useNavigate();
    const { delete_client } = useContext(context);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleSuccess = async() => {
        await delete_client(id_client)
        if(text === "cliente"){
            navigate(`/home`);
        }

        if(text === "proveedor"){
            navigate(`/suppliers`);
        }
        setShow(false)
    }
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="danger" onClick={handleShow}>
          Eliminar {text}
        </Button>
  
        <Modal show={show} onHide={handleClose} style={{width: "100vw"}}>
          <Modal.Header closeButton>
            <Modal.Title>¿Eliminar este {text}?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Si aceptas, se eliminará por completo este {text}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleSuccess}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default ModalDeleteClient