import React from 'react'
import { Link } from 'react-router-dom'


const BoxHome = ({text, total, id_user}) => {
  return (
    <Link className={total>= 0 && text === "$ Total balance" ? "boxHome greenBox" : total < 0 && text === "$ Total balance" ? "boxHome redBox" : "boxHome"} 
      to={
        text==="Clientes" ? "/all_clients": 
        text==="Proveedores" ? "/all_suppliers": 
        text==="Adeudos totales" ? "/to_pay":
        text==="Adeudos proveedores" ? "/to_pay_suppliers":
        text==="Órdenes" ? `/detail_client/${id_user}/orders_client`:
        text==="$ Total depósitos" ? `/detail_client/${id_user}/deposits_client`: ""
        }
      >
          <h4>{text}</h4>
          <hr />
          <p>Total: <b>{total}</b></p>
    </Link>
  )
}

export default BoxHome