import { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import clienteAxios from '../config/clienteAxios';

import { context } from "../context/context";
import { useNavigate } from "react-router-dom";

const ModalEditClient = ({supplier}) => {
    const { get_data_client, dataClient, get_data_home } = useContext(context);
    const [show, setShow] = useState(false);

    const [newUser, setNewUser] = useState({
        name: dataClient.name,
        cell_phone: dataClient.cell_phone,
        extra_phone: dataClient.extra_phone
    });

    const navigate = useNavigate();

    const handleSuccess = async() => {

        if(!newUser.name || !newUser.cell_phone){
            toast.error("Se requiere el nombre y mínimo un teléfono", {
                position: "top-center",
                theme: "dark",
            });
            return null
        }

        try {

            if(supplier){
              newUser.supplier = true
            }

            const response = await clienteAxios.put(`/api/client/edit_client/${dataClient._id}`, newUser);
            get_data_home()
            await get_data_client(response.data.data._id)
            if(supplier){
                navigate(`/detail_supplier/${response.data.data._id}`);
            }else{
                navigate(`/detail_client/${response.data.data._id}`);
            }
            setShow(false)
        } catch (error) {
            toast.error("Error en el servidor", {
                position: "top-center",
                theme: "dark",
            });
        }

        
    };
    const handleClose = () => {

        setNewUser({
            name: dataClient.name,
            cell_phone: dataClient.cell_phone,
            extra_phone: dataClient.extra_phone
        })

        setShow(false)
    };
    const handleShow = () => setShow(true);


    const handleChange = (e) => {
        setNewUser({ ...newUser, [e.target.name]: e.target.value });
    };


  return (
    <>
      <Button variant="warning" onClick={handleShow}>
        {supplier ? "Editar proveedor":"Editar cliente"}
      </Button>

      
        <Modal show={show} onHide={handleClose} style={{width: "100vw"}}>
        <Modal.Header closeButton>
          <Modal.Title>{supplier ? "Editar proveedor":"Editar cliente"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nombre (requerido)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre del cliente"
                name="name"
                autoFocus
                onChange={handleChange}
                value={newUser.name}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Teléfono (requerido)</Form.Label>
              <Form.Control
                type="text"
                name="cell_phone"
                placeholder="5555555555"
                onChange={handleChange}
                value={newUser.cell_phone}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Teléfono opcional</Form.Label>
              <Form.Control
                type="text"
                name="extra_phone"
                placeholder="5555555555"
                onChange={handleChange}
                value={newUser.extra_phone}
              />
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" onClick={handleSuccess}>
            Editar
          </Button>
        </Modal.Footer>
        </Modal>
      <ToastContainer/>
    </>
  )
}

export default ModalEditClient
