import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import AllOrders from "../pages/AllOrders";
import CreateOrder from "../pages/CreateOrder";
import ToDeliver from "../pages/ToDeliver";
import ToPay from "../pages/ToPay";
import AllClients from "../pages/AllClients";
import CreateClient from "../pages/CreateClient";
import DeleteClient from "../pages/DeleteClient";
import AllAdmin from "../pages/AllAdmin";
import CreateAdmin from "../pages/CreateAdmin";
import DeleteAdmin from "../pages/DeleteAdmin";
import PrivateRoute from "./PrivateRoutes";
import DetailClient from "../pages/DetailClient";
import SearchClient from "../pages/SearchClient";
import AllDeposits from "../pages/AllDeposits";
import { Suppliers } from "../pages/Suppliers";
import DetailSupplier from "../pages/DetailSupplier";
import AllSuppliers from "../pages/AllSuppliers";
import ToPaySuppliers from "../pages/ToPaySuppliers";
import OnlyClients from "../pages/OnlyClients";

const RootRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/only_clients/:id_client" element={<OnlyClients />} />
      <Route path="/create_admin" element={<CreateAdmin />} />
      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<Home />} />
        <Route path="/all_orders" element={<AllOrders />} />
        <Route path="/suppliers" element={<Suppliers />} />
        <Route path="/create_order" element={<CreateOrder />} />
        <Route path="/to_deliver" element={<ToDeliver />} />
        <Route path="/to_pay" element={<ToPay />} />
        <Route path="/to_pay_suppliers" element={<ToPaySuppliers />} />
        <Route path="/all_clients" element={<AllClients />} />
        <Route path="/all_suppliers" element={<AllSuppliers />} />
        <Route path="/create_client" element={<CreateClient />} />
        <Route path="/delete_client" element={<DeleteClient />} />
        <Route path="/all_admin" element={<AllAdmin />} />
        <Route path="/delete_admin" element={<DeleteAdmin />} />
        <Route path="/detail_client/:id_client" element={<DetailClient />} />
        <Route path="/detail_supplier/:id_client" element={<DetailSupplier />} />
        <Route path="/detail_client/:id_client/orders_client" element={<AllOrders />} />
        <Route path="/detail_client/:id_client/deposits_client" element={<AllDeposits />} />
        <Route path="/search_client/:search_item" element={<SearchClient />} />
      </Route>
    </Routes>
  );
};

export default RootRoutes;
