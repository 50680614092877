import React from 'react'
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { context } from "../context/context";
import moment from 'moment-timezone';
import ModalEditOrder from '../components/ModalEditOrder';
import ModalDeleteOrder from '../components/ModalDeleteOrder';


const AllOrders = () => {

  let { id_client } = useParams();
  const { get_orders_client, orders_client, close_client_orders } = useContext(context);

  useEffect(() => {
      
    get_orders_client(id_client)
  
    return () => {
      close_client_orders();
    };

}, [])

  return (
    <div className='allClients'>
        <div className='containerTable'>
        { orders_client.length > 0 && (
        <>
        <h3>Órdenes de: {orders_client[0].client_id.name}</h3>
          <Table striped="columns" bordered hover variant="primary">
            <thead>
              <tr>
                <th><h6>Fecha</h6></th>
                <th><h6>Kilogramos</h6></th>
                <th><h6>Precio</h6></th>
                <th><h6>Total</h6></th>
                <th><h6>Notas</h6></th>
                <th><h6>Editar</h6></th>
                <th><h6>Eliminar</h6></th>
                <th><h6>Producto</h6></th>
                <th><h6>Registrado por</h6></th>
              </tr>
            </thead>
            <tbody>
          {orders_client.map(order=>(
            <tr key={order._id}>
                <td>{moment.tz(order.date_register, 'America/Mexico_City').format("DD-MM-YYYY")}</td>
                <td>{order.kilograms} kg</td>
                <td>${order.price}</td>
                <td>${order.total}</td>
                <td>{order.notes}</td>
                <td><ModalEditOrder orderData={order}/></td>
                <td><ModalDeleteOrder orderData={order}/></td>
                <td>{order.product}</td>
                <td>{order.created_by.name}</td>
            </tr>
          ))}
          
          </tbody>
                </Table>
        </>
      )}
      {orders_client.length === 0 && (
            <h2>No hay órdenes realizadas</h2>
          )}
        </div>
    </div>
  )
}

export default AllOrders