import { useContext, useEffect } from "react";
import BoxHome from "../components/BoxHome";
import { context } from "../context/context";
import ModalNewClient from "../components/ModalNewClient";
import LoadingComponent from "../components/LoadingComponent";

const Home = () => {
  const { get_data_home, dataHome, loading, close_home } = useContext(context);

  useEffect(() => {
    get_data_home()

    return () => {
      close_home();
    };
  }, [])
  


  return (
    <div className="home">
      
      <div className="modalButtonClient">
        <ModalNewClient />
      </div>
      <div className="containerBox">
        {loading && <LoadingComponent/>}
        {dataHome.map(boxData =>(
          <BoxHome key={boxData.text} text={boxData.text} total={boxData.data} />
        ))}

      </div>
      
    </div>
  );
};

export default Home;
