import Button from "./Button";
import AssignmentIcon from '@mui/icons-material/Assignment';

const NewOrder = () => {
  return (
    <form className="neworder">
<div className="icon"><label htmlFor="icon"><AssignmentIcon/></label></div>
      <div className="order">
        <label htmlFor="name">Nombre:</label>
        <input type="text" id="nombre" placeholder="Nombre del cliente" />
      </div>
      <div className="order">
        <label htmlFor="product">Producto:</label>
        <input type="text" id="producto" placeholder="Producto" />
      </div>
      <div className="order">
        <label htmlFor="kilos">Kilos (Kg):</label>
        <input type="number" id="kilos" placeholder="Kilogramos" />
      </div>
      <div className="order">
        <label htmlFor="price">Precio por kg.:</label>
        <input type="number" id="price" placeholder="$0" />
      </div>
      <div className="order">
        <label htmlFor="total">Total:</label>
        <input type="number" id="total" placeholder="$0" />
      </div>
      <div className="order">
        <label htmlFor="createby">Creado por:</label>
        <input type="text" id="createby" placeholder="Nombre del vendedor" />
      </div>
      <div className="order">
        <label htmlFor="status">Estatus:</label>
        <input type="text" id="status" placeholder="Estado de la orden" />
      </div>
      <Button label="Crear orden 🍉" />
      <a className="cancel" href="cancel">Cancelar</a>
    </form>
  );
};

export default NewOrder;
