const reducer = (state, action) => {
    const objReducer = {
      ADD_USER: () => {
        return {
          ...state,
          user: action.payload,
        };
      },
      DELETE_MY_POST: () => {
        return {
          ...state,
          myposts: state.myposts.filter((post) => post.Id !== action.payload),
        };
      },
      DELETE_USER: () => {
        return {
          ...state,
          user: null,
        };
      },
      SET_ALL_USERS: () => {
        return {
          ...state,
          allUsers: action.payload,
        };
      },
      SET_FOLLOWING: () => {
        return {
          ...state,
          following: action.payload,
        };
      },
      SET_FOLLOWERS: () => {
        return {
          ...state,
          followers: action.payload,
        };
      },
      SET_MY_POSTS: () => {
        return {
          ...state,
          myposts: action.payload,
        };
      },
      SET_POSTS: () => {
        return {
          ...state,
          posts: action.payload,
        };
      },
      LOGOUT: () => {
        return {
          admin: null,
          clients: [],
          orders: [],
          loading: false,
          error: false,
          login: false,
          dataHome: [],
          dataClient: null,
          boxDataUser: [],
          resultsSearchClients: [],
          clients_to_pay: [],
          orders_client: [],
          deposits_client: [],
          dataHomeSupplier: [],
          suppliers_to_pay: []
        };
      },
  
      SHOW_MODAL: () => {
        return {
          ...state,
          modal: action.payload,
        };
      },

      LOGIN: () => {
        return {
          ...state,
          login: action.payload
        }
      },

      GET_DATA_HOME: () => {
        return {
          ...state,
          dataHome: action.payload
        }
      },

      CLOSE_HOME: () => {
        return {
          ...state,
          dataHome: []
        }
      },

      GET_DATA_HOME_SUPPLIER: () => {
        return {
          ...state,
          dataHomeSupplier: action.payload
        }
      },

      GET_DATA_CLIENT: () => {
        return {
          ...state,
          dataClient: action.payload.client,
          boxDataUser: action.payload.listData
        }
      },

      CLOSE_CLIENT: () => {
        return {
          ...state,
          dataClient: null,
          boxDataUser: []
        }
      },
      SEARCH_CLIENTS: () => {
        return {
          ...state,
          resultsSearchClients: action.payload
        }
      },
      GET_DATA_TO_PAY: () => {
        return {
          ...state,
          clients_to_pay: action.payload
        }
      },
      GET_DATA_TO_PAY_SUPPLIERS: () => {
        return {
          ...state,
          suppliers_to_pay: action.payload
        }
      },
      GET_ORDERS_CLIENT: () => {
        return {
          ...state,
          orders_client: action.payload
        }
      },
      CLOSE_CLIENT_ORDERS: () => {
        return {
          ...state,
          orders_client:[]
        }
      },
      GET_DEPOSITS_CLIENT: () => {
        return {
          ...state,
          deposits_client: action.payload
        }
      },
      CLOSE_CLIENT_DEPOSITS: () => {
        return {
          ...state,
          deposits_client:[]
        }
      },
      DELETE_CLIENT: () => {
        return {
          ...state,
          dataClient: null,
          boxDataUser: []
        }
      },
      ALL_CLIENTS: () => {
        return {
          ...state,
          clients: action.payload
        }
      },

      CHARGING_DATA: () => {
        return {
          ...state,
          loading: action.payload
        }
      },


    };
  
    return objReducer[action.type] ? objReducer[action.type]() : state;
  };
  
  export default reducer;
  