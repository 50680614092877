import { useParams } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import BoxHome from '../components/BoxHome';
import ModalNewDeposit from '../components/ModalNewDeposit';
import { context } from "../context/context";
import ModalNewOrder from '../components/ModalNewOrder';
import ModalDeleteClient from '../components/ModalDeleteClient';
import ModalEditClient from '../components/ModalEditClient';

const DetailSupplier = () => {

    let { id_client } = useParams();

    const { get_data_client, dataClient, boxDataUser, close_client } = useContext(context);

    useEffect(() => {
      
        get_data_client(id_client)
      
        return () => {
            close_client();
        };

    }, [])
    

  return (
    <div className='detailClient'>
        {dataClient && (
            <div className='headerDetailClient'>
                <div className='cardClientDetail'>
                    <h4>Nombre: {dataClient.name}</h4>
                    <p>Teléfono: {dataClient.cell_phone}</p>
                    <p>Teléfono opcional: {dataClient.extra_phone}</p>
                    <p>Link cliente: {`https://sandiaschavalin.com/only_clients/${dataClient._id}`}</p>
                </div>
                <div className='containerButtonDetail'>
                    <ModalNewOrder id_client={dataClient._id}/>
                    <ModalNewDeposit id_client={dataClient._id}/>
                    <ModalEditClient supplier={dataClient.supplier}/>
                    <ModalDeleteClient id_client={dataClient._id} text={dataClient.supplier ? "proveedor" : "cliente"}/>
                </div>
            </div>
        )}
        <div className='containerBoxClient'>
            {boxDataUser.map(dataBox =>(
                <BoxHome key={dataBox.text} text={dataBox.text} total={dataBox.data} id_user={id_client}/>
            ))}
        </div>

    </div>
  )
}

export default DetailSupplier